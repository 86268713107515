@font-face {
  font-family: "SFProDisplay-regular";
  src: url("assets/fonts/SFProDisplay-Regular.eot");
  src: url("assets/fonts/SFProDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("assets/fonts/SFProDisplay-Regular.woff") format("woff"),
    url("assets/fonts/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --text-white: #ffffff;
  --btn-bg-black: #231f20;
  --text-black: #000000;
  --btn-danger: #bf1e2e;
  --icon-gray-color: #787676;
  --light-gray-color: #979797;
  --secondary-gray: #7e7e7e;
  --text-black-color: #231f20;
  --border-color: #a19fa0;
  --primary-btn-color: #bf1e2e;
  --main-body-bg: #f2f2f2;
  --gray-secondary-color: #d4d4d4;
  --card-border: #f76420;
  --text-dark-yellow: #f9b11f;
  --text-blue-600: #5890ff;
  --bg-light-purple: #cbc3fe;
  --text-purple: #bc29bf;
  --text-blue: #573bff;
  --card-bg-color: #fff;
  --input-bg-color: #fff;
  --label-color: #8f96a9;
}

:root.blue {
  --btn-danger: #bf1e2e;
  --icon-gray-color: #787676;
  --light-gray-color: #979797;
  --text-black-color: #231f20;
  --primary-btn-color: #5890ff;
  --main-body-bg: #f2f2f2;
  --gray-secondary-color: #d4d4d4;
  --orange-border-color: #f76420;
  --text-blue-600: #5890ff;
  --card-border: #5890ff;
  --card-bg-color: #fff;
}
:root.green {
  --btn-danger: #bf1e2e;
  --icon-gray-color: #787676;
  --light-gray-color: #979797;
  --text-black-color: #231f20;
  --primary-btn-color: #709c9b;
  --main-body-bg: #f2f2f2;
  --gray-secondary-color: #d4d4d4;
  --orange-border-color: #f76420;
  --text-blue-600: #709c9b;
  --card-border: #709c9b;
  --card-bg-color: #fff;
}
body {
  font-family: "SFProDisplay-regular", sans-serif !important;
}
.dropdown-menu .dropdown-item {
  padding: 4px 16px !important;
}
.order-details-table .trDataWrapper:first-child .navbar-nav .nav-item.show .dropdown-menu,
.order-details-table .trDataWrapper:first-child .dropdown.show .dropdown-menu {
  transform: translate3d(-150px, -58.5px, 0px) !important;
}
.test-details-table .trDataWrapper:first-child .navbar-nav .nav-item.show .dropdown-menu,
.test-details-table .trDataWrapper:first-child .dropdown.show .dropdown-menu {
  transform: translate3d(-30px, -58.5px, 0px) !important;
}
.test-details-table .trDataWrapper:nth-child(2) .navbar-nav .nav-item.show .dropdown-menu,
.test-details-table .trDataWrapper:nth-child(2) .dropdown.show .dropdown-menu {
  transform: translate3d(-15px, -35.5px, 0px) !important;
}
.test-done-table .trDataWrapper:first-child .navbar-nav .nav-item.show .dropdown-menu,
.test-done-table .trDataWrapper:first-child .dropdown.show .dropdown-menu {
  transform: translate3d(-149px, -65.5px, 0px) !important;
}

.test-done-table .trDataWrapper:nth-child(2) .navbar-nav .nav-item.show .dropdown-menu,
.test-done-table .trDataWrapper:nth-child(2) .dropdown.show .dropdown-menu {
  transform: translate3d(-149px, -105.5px, 0px) !important;
}
.documents-done-table .trDataWrapper:first-child .navbar-nav .nav-item.show .dropdown-menu,
.documents-done-table .trDataWrapper:first-child .dropdown.show .dropdown-menu {
  transform: translate3d(-145px, -15.5px, 0px) !important;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.minWidth50 {
  min-width: 350px;
}
.font-bold {
  font-weight: bold;
}
.bg-purple {
  background-color: var(--bg-light-purple);
}
.text-purple {
  color: var(--text-purple);
}
.text-blue {
  color: var(--text-blue);
}
.text-dark-yellow {
  color: var(--text-dark-yellow);
}
.text-dark-danger {
  color: var(--primary-btn-color);
}
.text-dark-danger-hover:hover {
  color: var(--primary-btn-color);
}
.text-primary-black {
  color: var(--text-black-color);
}
.text-primary-black-hover:hover {
  color: var(--text-black-color);
}
.secondary-gray {
  color: var(--secondary-gray);
}
.separate-border-tr {
  border-collapse: separate;
  border-spacing: 0 5px;
}
.w-ThreePercent {
  width: 3%;
}
.w-TwelvePercent {
  width: 12%;
}
.w-EightPercent {
  width: 8%;
}
.w-sevenPercent {
  width: 7%;
}
.w-FivePercent {
  width: 5%;
}
.w-TenPercent {
  width: 10%;
}
.w-FifteenPercent {
  width: 15%;
}
.content-body {
  padding: 18px 15px !important;
}
.content > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.content > .container-fluid > .row {
  margin-left: 0;
  margin-right: 0;
}
.content > .container-fluid .row > .col-md-12 {
  padding-left: 0;
  padding-right: 0;
}
.linkHoverDangerText:hover,
.linkHoverDangerText:active,
.linkHoverDangerText:focus {
  color: var(--primary-btn-color);
}
.object-fit-contain {
  object-fit: contain;
}
.content .striped-tabled-with-hover.bg-transparent.card {
  border: 0;
}
.second-modal {
  /* position: fixed;
  inset: 0;
  width: 100%; */
  z-index: 1056;
  background: rgba(0, 0, 0, 0.5);
}
.modal-padding-0 {
  padding-left: 0px !important;
}

.text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}
.text-primary-color {
  color: #bf1e2e;
}
.table-fixed {
  table-layout: fixed;
}
.text-ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right-3 {
  right: 15px;
}
.top-1 {
  top: 5px;
}
.fs-7 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 14px;
}
.shows-screen-parent .table > thead {
  background-color: #fff;
}
.shows-screen-parent .table > thead > tr > th {
  font-weight: 500 !important;
  padding-bottom: 12px !important;
}

.login-right-side h3 {
  font-weight: 600;
  color: #26335a;
  font-size: 28px;
  letter-spacing: 1.17px;
  line-height: 29px;
}
.login-input-label {
  font-size: 16px;
  font-weight: 500;
  color: var(--label-color);
  letter-spacing: 0.19px;
  line-height: 19px;
}
.login-inner-right-side {
  max-width: 440px;
  margin: auto;
}
.tag-line {
  font-size: 18px;
  font-weight: normal;
  color: #26335a;
  letter-spacing: 0.25px;
  line-height: 25px;
}
.login-right-side .phoneInput .PhoneInputInput {
  border: 0 !important;
}
.login-right-side .PhoneInput {
  border: 1px solid rgb(196, 196, 196) !important;
  border-radius: 3px;
}
.login-right-side .PhoneInput .PhoneInputCountry {
  padding-left: 0.35em !important;
}
.profileButton {
  margin-right: 10px;
  /*margin-bottom: 5px;*/
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4.5px;
  padding-top: 4.5px;
  min-width: 130px;
  font-size: 1rem;
  border-radius: 0;
  font-weight: normal;
}
.headerButton.btn-primary {
  padding-block: 5px;
}
/* add new btn  */
.headerButton {
  margin-right: 10px;
  /*margin-bottom: 5px;*/
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4.5px;
  padding-top: 4.5px;
  min-width: 130px;
  font-size: 1rem;
  border-radius: 0;
  font-weight: normal;
}

.headerButton.btn-primary {
  background-color: var(--primary-btn-color);
  border-color: var(--primary-btn-color);
  color: var(--text-white);
}

.headerButton.btn-primary:hover,
.headerButton.btn-primary:active,
.headerButton.btn-primary:focus {
  background-color: var(--primary-btn-color);
  box-shadow: none;
  border-color: var(--primary-btn-color) !important;
}

.headerButton.btn-secondary {
  background-color: var(--btn-bg-black);
  border-color: var(--btn-bg-black);
  color: var(--text-white);
}

.headerButton.btn-secondary:hover,
.headerButton.btn-secondary:active,
.headerButton.btn-secondary:focus {
  background-color: var(--btn-bg-black);
  box-shadow: none;
}
.modalButtons.headerButton .downloadBtn,
.modalButtons.headerButton .viewBtn {
  border-color: gainsboro;
}

.modalButtons.headerButton .downloadBtn:active {
  background-color: antiquewhite;
}

.modalButtons.headerButton .viewBtn:active {
  background-color: aliceblue;
}
.system-settings-wrapper .headerButton.btn-secondary {
  margin-bottom: 10px;
}
/* add new btn  */
/* .crew-member-profile .testAdmin.createClientsWrapper .form-group-wrapper > input {
  height: 40px !important;
  min-height: 40px !important;
} */
.profileButton.btn-primary,
.modalButtons.btn-primary {
  background-color: var(--primary-btn-color);
  border-color: var(--primary-btn-color);
  color: var(--text-white);
  opacity: 1;
  border-radius: 0;
}
.profileButton.btn-primary:hover,
.profileButton.btn-primary:active,
.profileButton.btn-primary:focus,
.modalButtons.btn-primary:hover,
.modalButtons.btn-primary:active,
.modalButtons.btn-primary:focus {
  background-color: var(--primary-btn-color);
  box-shadow: none;
  border-color: var(--primary-btn-color);
}
.profileButton.btn-secondary,
.modalButtons.btn-secondary {
  background-color: var(--text-black-color);
  border-color: var(--text-black-color);
  color: var(--text-white);
  border-color: var(--text-black-color);
  opacity: 1;
  border-radius: 0;
}
.profileButton.btn-secondary:hover,
.profileButton.btn-secondary:active,
.profileButton.btn-secondary:focus,
.modalButtons.btn-secondary:hover,
.modalButtons.btn-secondary:active,
.modalButtons.btn-secondary:focus {
  background-color: var(--text-black-color);
  box-shadow: none;
  border-color: var(--text-black-color);
}

.flex-basis-1 {
  flex-basis: 100% !important;
}
.max-w-100 {
  max-width: 100% !important;
}

.custom-option {
  transition: background 60ms;
}

.custom-option:hover {
  transition-delay: 60ms;
  background: #deebff;
}

.custom-option.custom-select__option--is-focused {
  background: none;
}

.custom-option.custom-select__option--is-selected {
  background: #2684ff;
}

.optListContent {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* margin-block: 2px; */
}

.listOption:last-child {
  border-bottom: 0;
}

.listOption:hover {
  background-color: #eaf6ff;
  color: #000;
}

.searchInput {
  max-width: 500px;
  padding-left: 10px;
  border-top: none !important;
  border-right: none !important;
}

.searchFilterButton {
  width: 130px;
  margin-right: 10px;
}

.customProviderText {
  display: flex;
  width: 100%;
  align-items: center;
}

.cancelSearchInput {
  position: absolute !important;
  right: 10px !important;
  top: 15px !important;
  font-size: 16px !important;
  cursor: pointer;
}
.cancelSearchInputWithLabel {
  position: absolute !important;
  right: 10px !important;
  top: 45px !important;
  font-size: 14px !important;
  cursor: pointer;
}

.optionListWrapper {
  position: absolute;
  left: 0;
  background-color: var(--card-bg-color);
  color: var(--text-black);
  z-index: 100;
  max-height: 220px;
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  width: 100%;
  padding: 0;
  margin: 0;
  /* box-shadow: 0px 0px 3px 1px; */
}

/* width */
.optionListWrapper::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.optionListWrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.optionListWrapper::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.optionListWrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Style for selected option */
.optionListWrapper .listOption.selected {
  background-color: #eaf6ff;
  color: #000; /* Change to your desired selected background color */
}

.listOption {
  border-bottom: 1px solid #e3e3e3;
  list-style: none;
  display: block;
  cursor: pointer;
  padding-block: 5px;
  padding-inline: 15px;
  /* margin-inline: 10px; */
}

.searchInputField {
  max-width: 90% !important;
}

.data-listing-form {
  margin-bottom: 15px;
}

.data-listing-form .table-headings-list {
  color: var(--text-black-color) !important;
  /* line-height: 1; */
  padding: 8px 12px;
  margin-bottom: 5px;
}

.data-listing-form .table-list-data {
  color: var(--text-black-color);
  padding: 8px 12px;
  font-size: 14px;
}

.pl-3 {
  padding-left: 1rem !important;
}

imagetr:hover {
  border: 1px solid transparent;
  border-color: #ab2330;
  border-width: 1.1px;
  margin: -1px;
}
.pt-72 {
  padding-top: 72px;
}
.user-table {
  table-layout: fixed;
  width: 100%;
}

.ellipsis {
  max-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.accessionListNo {
  position: relative;
}
.accessionEdit {
  display: none; /* Hide the edit button by default */
  position: absolute;
  top: 50%;
  right: 5px; /* Adjust this value to position the button from the right edge */
  transform: translateY(-50%);
  z-index: 3;
  background-color: #fff; /* Set the background color */
}

.accessionListNo:hover .accessionEdit {
  display: block; /* Show the edit button when hovering over the cell */
}

.fixed-table {
  table-layout: fixed;
}
.btn-outline-danger {
  border-color: red;
  color: red;
}
.btn:disabled {
  color: grey;
  /* border-color: grey; */
}
/* .table > tbody > tr > td.tooltip {
  width: auto;
  overflow: visible;
  z-index: 11;
  background-color: #FFFFFF;
} */
.colFirstWrapper {
  padding-left: 6px !important ;
}
.table > tbody > tr > td.icon {
  width: 45px;
  text-overflow: inherit;
  overflow: visible;
  white-space: normal;
}

.table > thead > tr > th.centered {
  text-align: center !important;
}

.table > tbody > tr > td.centered {
  text-align: center !important;
}

.table > thead > tr > th.centered > div {
  justify-content: center !important;
}

.table > thead > tr > th.right {
  text-align: right !important;
}

.table > tbody > tr > td.right {
  text-align: right !important;
}

.table > thead > tr > th.right > div {
  justify-content: flex-end !important;
}

.appleEventRow > td {
  color: gray !important;
}

.hipaaTextArea {
  width: 100%;
  height: 90%;
}

.hipaaDialogue {
  max-height: 100vh;
  max-width: 100vw;
}

.hipaaBody {
  max-height: 90%;
}

/* .modal {
  overflow-x: visible;
  overflow-y: visible;
} */
.employeeProfile label {
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 400;
  color: #9a9a9a;
  margin-bottom: 0px;
}
.overFlow-y-hidden {
  overflow-y: hidden;
}
.radioBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}
.radioBox > input {
  margin-top: 0;
}
.rdioBox > label {
  margin-left: 5px;
}

.textAreaMessageInput .css-b62m3t-container {
  width: 100%;
}

.switch input:checked + .slider.blue {
  background-color: #1f77d0;
}

.insCard-background-blue {
  background-color: #1f78d020;
  margin-bottom: 10px !important;
}
.file-width-cust {
  max-width: 52px;
  margin: 0 auto;
}
.MessageInput {
  height: 140px;
  min-height: 10vh;
  padding: 10px;
  background-color: var(--input-bg-color);
  color: var(--text-black);
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.MessageInput:focus {
  box-shadow: none;
  outline: none;
}

.crewPhoneInput > input {
  border-radius: 5px;
  padding: 2px 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  min-height: 36px;
}
.modalLineHeaders {
  padding-bottom: 0;
  margin-top: 10px;
  margin-bottom: 0;
}
.modalLineHeadersWithoutMargin {
  padding-bottom: 0;

  margin-bottom: 0;
}

.testDetailsSelector {
  padding-bottom: 0;
  margin-bottom: 0;
}

.testDetailsSelectorMarginTop {
  margin-top: 0.6rem !important;
}

.css-6j8wv5-Input {
  margin: 0 2px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.radioHead {
  display: flex;
}
.radioHead > * {
  flex-basis: 100%;
}

.roleSelector {
  margin: 0;
}

.buttonHeader {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.dictionary_search {
  width: 30%;
}

.disable-cursor {
  cursor: not-allowed !important;
}

/* Document Section Css Open*/

.file-upload-area {
  background-color: var(--main-body-bg); /* Light gray background color */
  width: 100%; /* Full width */
  min-height: 150px; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--icon-gray-color);
  border-radius: 10px;
  cursor: pointer;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
}

.secondary-insurance-head .heading-insurance {
  font-weight: 800;
  border: none !important;
}

.secondary-insurance-change {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 95%;
  padding: 10px;
  margin: 10px;
  overflow: hidden;
}

.secondary-insurance-change .secondary-edit-input {
  background-color: transparent !important;
}

.secondary-insurance-change .w-75.flex-column.d-flex span {
  font-size: 15px; /* Smaller font size */
  color: var(--dark-gray-text); /* Gray color for text */
}

.secondary-insurance-change .d-flex.flex-column.justify-content-around.align-items-center img,
.secondary-insurance-change .d-flex.flex-column.justify-content-around.align-items-center i {
  width: 18px;
  cursor: pointer;
  color: var(--dark-gray-text); /* Gray color for icons */
  line-height: 1; /* Minor line height for compact appearance */
}

/* Style the download icon */
.secondary-insurance-change .d-flex.flex-column.justify-content-around.align-items-center i:hover {
  color: var(--btn-danger) !important; /* Change the color on hover */
}

.notesIcon {
  width: 18px;
  height: 18px;
  margin-bottom: 5px;
}

.hipaaTextArea {
  width: 100%;
  height: 90%;
}

.popOver-centered {
  text-align: center !important;
}

.hipaaDialogue {
  max-height: 100vh;
  max-width: 100vw;
}

.scrollable-div {
  /* Set a fixed height for the scrollable div */
  /* border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgba(20, 19, 19, 0.5); */
  max-height: 300px !important; /* Adjust the height as needed */
  min-height: 150px !important;
  /* Add scrollbars when content overflows */
  overflow-y: auto; /* Vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar if not needed */
  scrollbar-width: thin;
}
/* Always show the scrollbar thumb in WebKit-based browsers */
.scrollable-div::-webkit-scrollbar {
  width: 8px;
}
.scrollable-div::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background color of the track */
}

/* Style the scrollbar thumb in WebKit-based browsers */
.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

/* Style the scrollbar thumb on hover in WebKit-based browsers */
.scrollable-div::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

/* Document Section Css Close */
.row-gap-3 {
  row-gap: 1rem;
}
.profileButton {
  /* margin-right: 10px; */
  /*margin-bottom: 5px;*/
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4.5px;
  padding-top: 4.5px;
}
.preRegisterationViewWrapper {
  width: calc(100% - 40px) !important;
}

a.downloadTest {
  color: #3472f7;
  text-decoration: none;
}

.hsWrapper {
  border: 1px solid gray;
  border-radius: 5px;
}
.filterFields {
  margin-top: 10px;
}

.filterDrop {
  width: 145px;
  margin-right: 10px;
}

.searchInput {
  max-width: 500px;
  padding-left: 10px;
  border-top: none !important;
  border-right: none !important;
}

.searchFilterButton {
  width: 130px;
  margin-right: 10px;
}

.changeRoleSelector {
  width: 175px;
}

.createButtons {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;
  padding-left: 8px;
}

.modalButtons {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;
  padding-left: 8px;
}

.headerNav {
  display: flex;
  justify-content: space-between;
}

.toggleButtonGroup {
  width: 100px;
}

.checkboxRow {
  display: flex;
  flex-direction: column;
}

.dateRangeFilter {
  border-radius: 5px;
  padding: 2px 10px;
  color: #212529;
  border: 1px solid #ddd;
  max-width: calc(100% - 174px);
  width: 100%;

  height: 40px;
}

.releaseCheckbox {
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.checkboxLabel {
  margin-top: 10px;
}

.createLabModal {
  min-width: 90vw !important;
  min-height: 90vh !important;
}

.createLabBody {
  display: flex;
  flex-wrap: wrap;
}

.labAddressInput {
  display: flex;
  flex-direction: column;
  width: 97%;
}

.notesInput {
  width: 97%;
}

.labModalInput {
  padding: 2px 10px;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.adminHomeTable td {
  border: none;
}

.adminHomeTable tr:hover {
  border: none;
}
.pdfView {
  flex: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pdfContainer {
  height: 170vh;
}

.footerText {
  font-size: 9px;
  padding-right: 10px;
}

.truncate {
  width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-right {
  text-align: right;
}
.pr-0 {
  padding-right: 0;
}
.emailWidth {
  width: 20%;
}
.tdWidth10 {
  width: 10%;
}
.tdWidth12 {
  width: 12%;
}
.tdWidth14 {
  width: 14%;
}
.tdWidth16 {
  width: 16%;
}
.tdWidth18 {
  width: 18%;
}
.realtionTdWidth {
  width: 25%;
}
.radioBox-wrapper span.radioButton {
  margin-left: 5px;
  font-size: 14px;
}
.testProfilePt {
  padding-top: 0.4rem;
}
.vaccination-status-name {
  font-size: 12px !important;
  margin-bottom: 5px !important;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
  min-height: 18px;
}
.vaccination-status {
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  min-height: 38px;
  display: inline-block;
}
.form-check > label,
.form-check > input {
  color: #212529;
  padding-left: 0 !important;
}

.innerWrapper {
  max-width: 100% !important;
}

.checkbox-list {
  column-count: 3;
  column-gap: 20px;
}

.form-group-wrapper .roleSelector {
  width: 100%;
}
.form-group-wrapper.activeEmploye .roleSelector {
  width: 50%;
}
.form-group-wrapper.activeEmploye .siteSelector {
  width: 50%;
}
.form-group-wrapper.activeEmploye .siteSelector .css-14el2xx-placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.form-group-wrapper .fa-times-circle {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: rgb(168, 38, 50);
}

.modal-2xl {
  max-width: 80%;
}

.modal-1xl {
  max-width: 70%;
}

.fa-star {
  color: #fcc603;
  font-size: 11px;
}
.fa-exclamation-circle {
  font-size: 15px;
  color: var(--amplify-primary-color);
}
.fa-check-circle {
  font-size: 15px;
  color: green;
}
.roleSelector .css-1s2u09g-control,
.roleSelector .css-1insrsq-control,
.siteSelector .css-1s2u09g-control,
.siteSelector .css-1insrsq-control,
.siteSelector .css-13cymwt-control {
  background-color: #f9f9f9;
  border-color: #a19fa0 !important;
  height: 40px !important;
}
.roleSelector .css-1s2u09g-control:hover,
.siteSelector .css-13cymwt-control:hover,
.siteSelector .css-1s2u09g-control:hover {
  border-color: #ddd !important;
}
.selectedTestsModal .siteSelector .css-1s2u09g-control {
  height: auto !important;
}
.section-testDetails {
  border: 1px solid gray;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  background-color: white;
}

/*.roleSelector .css-qc6sy-singleValue, .siteSelector .css-qc6sy-singleValue  {*/
/*  color: #888888;*/
/*}*/
/*.roleSelector .css-1pahdxg-control .css-qc6sy-singleValue {*/
/*  color: #000000 !important;*/
/*}*/

.relationContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f2f2f2;
  color: black;
  padding: 10px;
  border-radius: 5px;
}
.profileRelationButton {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  min-width: 20px;
  max-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.createClientsWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
}

.form-group-wrapper .radioBox-wrapper {
  display: inline-block;
  min-width: 85px;
}
.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-top: 0;
  margin-left: 10px;
}
.inputFileWrapper {
  width: 120px;
  height: 120px;
  border: 1px solid #ddd;
  border-radius: 50%;
}
.inputFileWrapper > input {
  opacity: 0;
  width: 100%;
  height: 100%;
}

.inputFileWrapperFull {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  text-align: center;
  /* border-radius: 50%;  */
}
.inputFileWrapperFull > input {
  /* opacity: 0; */
  width: 100%;
  height: 100%;
}
.inputFileWrapperFull > img {
  width: 100%;
  height: 100%;
  /* max-height: 190px;
  min-height: 190px; */
  /* border-radius: 50%; */
}
.checkBoxWrapper {
  display: flex;
  align-items: center;
}
.checkBoxWrapper > span {
  /*flex: 0 0 60%;*/
  /*max-width: 60%;*/
  width: 100%;
}
.checkBoxWrapper input {
  flex: 0 0 auto;
}
.showsModal .checkBoxWrapper {
  width: 100%;
  /*column-gap: 15px;*/
}
.checkbox.checkBoxWrapper {
  justify-content: space-between;
}
.showsModal div .checkBoxWrapper span {
  width: 100%;
  font-size: 14px;
}
:root {
  --amplify-primary-color: rgb(168, 38, 50);
  --amplify-primary-tint: #31465f;
  --amplify-primary-shade: rgb(168, 38, 50);
}
.dashboard-page {
  display: flex;
  justify-content: flex-start;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
}
.dashboard-tab {
  flex: 0 0 calc(33.33% - 10px);
  max-width: calc(33.33% - 10px);
  width: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.dashboard-tab h4 {
  font-size: 24px;
  margin-top: 0;
  color: #565656;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding: 30px 15px 15px;
}
.pac-container {
  z-index: 1100 !important;
}
.dashboard-tab p {
  font-size: 24px;
  color: var(--primary-btn-color);
  margin: 0;
  padding: 0 15px 30px;
}
.labsModalWrapper {
  display: flex;
  column-gap: 15px;
}
.labsModalWrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 7px);
  max-width: calc(50% - 7px);
}
.labsModalWrapper .form-group-wrapper table > tbody {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}
.labsModalWrapper .form-group-wrapper table thead tr,
.labsModalWrapper .form-group-wrapper table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.result-filter-width {
  flex: 0 0 calc(100% - 174px);
  max-width: calc(100% - 174px);
  width: 100%;
}

.test-details-wrapper {
  border: 1px solid #e3e3e3;
}
.test-details-header {
  padding-bottom: 15px !important;
  border-bottom: 1px solid var(--border-color) !important;
}
.test-details-wrapper .card-text {
  font-size: 14px;
}
.test-details-wrapper .card-text > span {
  font-size: 14px;
  color: var(--icon-gray-color);
  min-width: 100px;
  display: inline-block;
}
.test-details-footer {
  border-top: 1px solid var(--light-gray-color) !important;
  padding-bottom: 0;
  margin-top: 5px;
}
.test-details-footer ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
.test-details-footer ul li {
  padding-block: 5px;
  padding-right: 5px;
  text-align: center;
  flex: 0 0 50%;
}
.test-details-footer ul li:not(:first-child) {
  border-left: 1px solid var(--light-gray-color);
  padding-left: 5px;
}
.test-details-wrapper .test-details-header label {
  color: var(--primary-btn-color);
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 0;
  text-transform: none;
}
.test-details-wrapper .imageWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 100px;
  max-height: 100px;
  height: 100px;
}
.test-details-wrapper .imageWrapper img {
  width: 100%;
  height: 70px;
  object-fit: contain;
}
.table-first-td:before {
  content: "";
  position: absolute;
  left: 0;
  height: 90%;
  width: 3px;
  background-color: #fcc603;
  top: 50%;
  border-radius: 5px 0 0 5px;
  transform: translateY(-50%);
}
.crew-minor-text {
  color: red;
  font-weight: 600;
}
.sidebarListing-badge {
  background-color: var(--primary-btn-color);
  color: white;
  margin-left: 5px;
  font-weight: bold;
  font-size: 13px;
  width: 20px;
  text-align: center;
  border-radius: 3px;
  box-shadow: 1px 2px #1a1919;
}
.sidebar-wrapper .logo {
  background-color: #f7f7f8;
  border-color: var(--primary-btn-color);
  border-width: 4px;
  height: 100px !important;
}

/*Dashboard page*/
.dashboard-wrapper {
  background-color: #f8f8f8;
}

.dashboard-wrapper .custom-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px !important;
}
.dashboard-wrapper .custom-card-body .detail img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.dashboard-wrapper .custom-card-body .detail .title {
  color: #464646;
  font-size: 16px;
  line-height: 1;
}
.dashboard-wrapper .custom-card-body .number {
  font-size: 24px;
  line-height: 1;
}
.dashboard-wrapper .total-test {
  background-color: #e7e4f9;
  border: 0;
}
.dashboard-wrapper .positive-test {
  background-color: #f1d8da;
  border: 0;
}
.dashboard-wrapper .negative-test {
  background-color: #dfedde;
  border: 0;
}
.dashboard-wrapper .inconclusive-test {
  background-color: rgba(59, 134, 255, 0.2);
  border: 0;
}
.dashboard-wrapper .total-number {
  color: var(--text-blue);
}
.dashboard-wrapper .positive-number {
  color: #bf1e2e;
}
.dashboard-wrapper .negative-number {
  color: #08a003;
}
.dashboard-wrapper .inconclusive-number {
  color: #3b86ff;
}

/*--search bar ---*/
.dashboard-wrapper .form {
  display: flex;
  flex-direction: row;
}
.dashboard-wrapper .search-field {
  height: 32px;
  padding: 2px 30px 2px 15px;
  border-radius: 100px;
  outline: none;
  border: 1px solid var(--light-gray-color);
  background-color: transparent;
  font-size: 13px;
  color: var(--light-gray-color);
  position: relative;
}
.dashboard-wrapper input::placeholder {
  font-size: 13px;
  color: var(--light-gray-color);
}

.dashboard-wrapper .search-icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
  color: var(--light-gray-color);
  position: absolute;
  right: 10px;
  top: 8px;
}
.dashboard-wrapper .custom-title {
  font-size: 22px;
  color: #231f2d;
}
.dashboard-wrapper .search-card {
  border: none;
  background-color: #f3f3f3;
  padding: 0 10px;
  margin-top: 15px;
}
.dashboard-wrapper .search-card .card-body {
  padding-block: 10px;
}
/*--table--*/
.dashboard-wrapper .dashboard-tables-data {
  background-color: #f8f8f8;
  table-layout: fixed;
  width: 100%;
}
.dashboard-wrapper .dashboard-tables-data tbody {
  display: block;
  max-height: 36.5vw;
  overflow-y: auto;
}
.dashboard-wrapper .dashboard-tables-data tbody tr {
  width: 100%;
  display: table;
}
.dashboard-wrapper .dashboard-tables-data tbody::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f1f1f1;
}

.dashboard-wrapper .dashboard-tables-data tbody::-webkit-scrollbar {
  width: 5px;
  background-color: #f1f1f1;
}

.dashboard-wrapper .dashboard-tables-data tbody::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
}
/**/

.dashboard-wrapper .dashboard-tables-data table > tbody .clientColor {
  position: absolute;
  top: -3px;
  left: 0;
}
.dashboard-wrapper .dashboard-tables-data table > tbody .clientColor svg {
  padding: 0;
  width: 12px;
  height: 12px;
}
.dashboard-wrapper .table {
  background-color: #ffffff;
  margin-bottom: 5px;
}
.dashboard-wrapper .table > tbody > tr > td {
  padding: 0 5px 5px 5px;
  width: 25%;
}
.dashboard-wrapper .table p {
  font-size: 12px;
  margin-bottom: 0;
}
.dashboard-wrapper .table span {
  display: inline-block;
  font-size: 12px;
}
.dashboard-wrapper .table .custom-title {
  color: var(--light-gray-color);
}
.dashboard-wrapper .table tr {
  border-bottom: 1px solid transparent;
  width: 100%;
  line-height: 1;
  display: table;
}
.dashboard-wrapper .border-bottom-transparent {
  border-bottom: 1px solid #eee9e9 !important;
  line-height: 1;
}
.dashboard-wrapper .border-bottom-transparent > td {
  padding-block: 5px !important;
}
.dashboard-wrapper .stats-title {
  font-size: 16px;
}
.dashboard-wrapper .stats-title-border {
  border-left: 1px solid var(--border-color);
}
.hello {
  background: red;
  width: 30px !important;
  height: 30px;
  display: inline-block;
}

/*crew member profile*/
.crew-member-profile-inner {
  background-color: white;
}
.scrollable-div {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgba(20, 19, 19, 0.5);
  max-height: 300px !important;
  min-height: 150px !important;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
/* .crew-member-profile-inner .personal-info-wrapper {
  border-bottom: 1px solid #e3e3e3;
} */
.crew-member-profile .imagePlaceholder {
  height: 32px !important;
}
.wrapper-profile label {
  color: #7a7878;
}
.wrapper-profile input::placeholder {
  color: #1f1b1c;
}
.wrapper-profile #Phone::placeholder {
  color: red;
}
.wrapper-profile .crew-image-wrapper {
  max-width: 120px;
  width: 100%;
  height: 120px;
  position: relative;
  margin: auto;
}
.wrapper-profile .crew-image-wrapper > input {
  position: absolute;
  bottom: -5px;
  width: 30px;
  right: 10px;
  opacity: 0;
  z-index: 1;
}
.profile-image img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.profile-image .camera-icon {
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ebebeb;
  position: absolute;
  bottom: -5px;
  right: 10px;
  align-items: center;
  justify-content: center;
}
.profile-image .camera-icon i {
  font-size: 14px;
  color: var(--light-gray-color);
}
.wrapper-profile .edit-icon {
  width: 18px;
}
.wrapper-profile .profile-title {
  font-size: 18px;
}
.wrapper-profile .profile-image > p {
  font-size: 16px;
}
.wrapper-profile .settings-profile span {
  color: var(--light-gray-color);
  font-size: 14px;
  padding: 0;
}
.wrapper-profile .settings-profile textarea {
  min-height: 100px;
  resize: none;
}

.settings-profile {
  border: 1px solid #cdcecf;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
}

.crew-member-profile .additional-info .cards-heading {
  font-size: 14px;
  color: var(--icon-gray-color);
  margin-bottom: 5px;
}
.crew-member-profile .additional-info .cards-heading span {
  color: var(--primary-btn-color);
}
.crew-member-profile-inner .border-right-1 {
  border-right: 1px solid #e3e3e3;
}
.crew-member-profile-inner .border-left-1 {
  border-left: 1px solid #e3e3e3;
}
/*switch toggle button*/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
  margin-bottom: 0 !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 4px;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 3px;
}

.switch input:checked + .slider {
  background-color: #1f77d0;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}
.switch input:disabled {
  cursor: default;
}
.switch .slider.round.disabled {
  cursor: default;
  opacity: 0.5;
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round:before {
  border-radius: 50%;
}

/*radio button style*/

.radio-button-wrapper input[type="radio"] {
  display: none;
}

.radio-button-wrapper label {
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  padding-left: 25px;
}

.isPositiveResult > td {
  background-color: #fff0f0 !important;
  color: #ff2f2f !important;
}

.radio-button-wrapper label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: var(--text-white);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all 500ms ease;
}
.radio-button-wrapper input[type="radio"]:checked + label::before {
  border-color: var(--btn-danger);
  background-color: var(--btn-danger);
  transition: all 0.5s ease-in;
}
.blue .radio-button-wrapper input[type="radio"]:checked + label::before {
  border-color: var(--primary-btn-color);
  background-color: var(--primary-btn-color);
  transition: all 0.5s ease-in;
}
.radio-button-wrapper.colorBlack input[type="radio"]:checked + label::before {
  border-color: #000000;
  background-color: #000000;
}
.crew-member-profile .additional-info-radios label.status {
  color: var(--icon-gray-color);
  line-height: 1;
  margin-bottom: 5px;
}

.labelYesNo {
  text-decoration: underline;
  font-weight: bold;
  min-width: 35px;
  margin-right: 8px;
  display: inline-block;
}
.formWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
  padding-left: 15px;
}
.selectTestWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
.imageWrapper {
  min-height: 150px;
}
.text-grey {
  color: grey !important;
}
.uploadFilesArea {
  margin-top: 36px;
}
.mnw-25 {
  min-width: 25% !important;
}
.w-20 {
  width: 20% !important;
}

/* add new  */
.currency-view {
  color: var(--btn-danger) !important;
  padding-left: 10px !important;
  font-weight: bold;
}
/* .createClientsWrapper .form-group-wrapper .fa-search {
  position: absolute;
  right: 10px;
  top: 36px;
} */
/* .form-group-wrapper .PhoneInput {
  width: calc(100% - 100px);
} */
.createClientsWrapper .leftSide {
  /* flex: 0 0 calc(50% - 15px); */
  flex: 0 0 calc(100% - 15px);
  /* max-width: calc(50% - 15px); */
  width: 100%;
  flex-wrap: wrap;
  column-gap: 15px;
}

.createClientsWrapper .ModalLeftSide {
  flex: 0 0 calc(33% - 15px);
  max-width: calc(33% - 15px);
  width: 100%;
  flex-wrap: wrap;
  column-gap: 15px;
}

/* .createClientsWrapper .leftSide,
  .createClientsWrapper .rightSide {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .createClientsWrapper .leftSide .modalPhoneInput.PhoneInput {
    flex: 0 0 calc(100% - 0px);
    max-width: calc(100% - 0px);
    width: 100%;
  } */
.createClientsWrapper .form-group-wrapper > label,
.createClientsWrapper .form-group-wrapper > input {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
}

.order-ict-view .order-ict-selector {
  flex: 0 0 calc(12.5% - 10px);
  max-width: calc(12.5% - 10px);
  width: 100%;
}
.order-ict-view {
  display: flex;
  gap: 10px;
}

.tset-clent-edit.createClientsWrapper .form-group-wrapper {
  flex: 0 0 calc(33% - 15px);
  max-width: calc(33% - 15px);
  width: 100%;
}

.tset-clent-edit.createClientsWrapper .form-group-wrapper {
  display: flex;
}
.tset-clent-edit .form-group-wrapper > label {
  flex: 0 0 30%;
  max-width: 30%;
  width: 100%;
  margin-top: 0;
  font-size: 14px;
}
.tset-clent-edit .form-group-wrapper.dateWrapper > input,
.tset-clent-edit .form-group-wrapper > input {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(70% - 15px);
  max-width: calc(70% - 15px);
  width: calc(70% - 15px);
}
.tset-clent-edit.createClientsWrapper .form-group-wrapper .modalPhoneInput.PhoneInput {
  width: 100%;
}

.create-order-4th-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.create-order-4th-item > div {
  width: 24%;
  padding: 10px;
  padding-right: 33px;
  box-sizing: border-box;
}

.create-order-3-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.create-order-3-item > div {
  width: 32.6%;
  padding: 10px;
  padding-right: 33px;
  box-sizing: border-box;
}

.modalInput {
  /* border-radius: 5px;
  padding: 2px 10px;
  background-color: #f9f9f9;
  border: 1px solid var(--border-color);
  min-height: 36px; */
  border-radius: 5px;
  padding: 2px 10px;
  background-color: var(--input-bg-color);
  border: 1px solid var(--border-color);
  color: var(--text-black);
  min-height: 45px;
}
.createClientsWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  line-height: 1;
  display: block;
}
.main-panel {
  background: var(--main-body-bg);
}
.main-panel .navbar {
  height: 78px !important;
  background-color: transparent !important;
}
.card .card-text.card-body {
  padding: 15px 30px;
}
.main-panel > .content {
  background-color: transparent !important;
}
.custom-separate-border-tr tr {
  border-bottom: 5px solid #f2f2f2;
}
.custom-lab-test .card .card-body .table {
  background-color: var(--main-body-bg);
}
/*select dropdown*/
.filterInputBar.dateRangeFilter.filerInputField,
.css-1s2u09g-control,
.css-1pahdxg-control,
.css-t3ipsp-control,
.css-13cymwt-control,
.css-16xfy0z-control {
  min-height: 45px !important;
  box-shadow: none !important;
  border-color: var(--border-color) !important;
  background-color: var(--input-bg-color) !important;
}

.tabSettings-wrapper .css-1insrsq-control {
  min-height: 45px !important;
}
/*react tabs styling*/
.system-settings-wrapper .react-tabs__tab-list {
  border-bottom: 0;
  margin-bottom: 0;
}

.system-settings-wrapper .react-tabs__tab--selected {
  border-radius: 10px 10px 0 0;
  background-color: var(--card-bg-color) !important;
}

.system-settings-wrapper .react-tabs__tab {
  padding: 6px 12px 16px;
  font-size: 1rem;
  /* background-color: var(--main-body-bg); */
  color: var(--text-black);
}
.testAdmin.createClientsWrapper .form-group-wrapper > label,
.testAdmin.createClientsWrapper .form-group-wrapper > input {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
  color: var(--text-black);
  background-color: var(--input-bg-color);
}
.createClientsWrapper .form-group-wrapper .modalPhoneInput.PhoneInput {
  border: 1px solid var(--border-color);
  min-height: 45px;
  background-color: var(--input-bg-color);
  color: var(--text-black);
  border-radius: 5px;
  padding-inline: 15px;
  position: relative;
}

.createClientsWrapper .form-group-wrapper .modalPhoneInput.PhoneInput input {
  border: 0;
  font-size: 14px;
  min-height: 100%;
  background-color: var(--input-bg-color);
  color: var(--text-black);
}
.testAdmin.createClientsWrapper .crew-profile-again .form-group-wrapper {
  line-height: unset;
}
.modalInput::placeholder {
  color: var(--icon-gray-color);
}
/* add new  */
.createRefernceWrapper .form-group-wrapper .modalPhoneInput.PhoneInput {
  border: 1px solid var(--border-color);
  min-height: 45px;
  background-color: var(--input-bg-color);
  color: var(--text-black);
  border-radius: 5px;
  padding-inline: 15px;
  position: relative;
}
.createRefernceWrapper .form-group-wrapper .modalPhoneInput.PhoneInput input {
  border: 0;
  font-size: 14px;
  min-height: 100%;
  background-color: var(--input-bg-color);
  color: var(--text-black);
}
.merged-input-container {
  display: inline-block; /* Or use "flex" for more control over alignment */
  border-radius: 5px;
  padding: 2px 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  min-height: 36px;
  height: 40px;
  width: 100%;
}

.merged-input {
  border: none; /* Remove individual input borders */
  font-size: 14px; /* Adjust font size as needed */
  height: 36px;
  background-color: #f9f9f9;
}

/* If you want to remove the border of the second input and make them look seamless */
.merged-input:last-child {
  border-left: none;
}
.popOver-centered {
  text-align: center !important;
}

.employee-phone-input input {
  width: 100% !important;
}

.seperateModal .modal-dialog .modal-content {
  border: 1px solid var(--border-color) !important;
  box-shadow: 0px 0px 0px 100vw rgba(0, 0, 0, 0.5) !important;
}

/* add new d  */
.site-select-test {
  width: 300px !important;
}

.order-select-test {
  width: 13.5vw !important;
}

.width-dit {
  max-width: 350px;
}
/* add new d  */
/* Resizable Style */

.empClaimTable {
  overflow-x: auto !important;
}

.empClaimTable .table {
  width: auto;
}

.claimTableTH div:nth-child(1) {
  resize: horizontal;
  overflow-x: auto;
  min-width: 100%;
  max-width: 500px;
  padding-inline-end: 20px;
}

.tools-td {
  text-align: left;
}

.tools-available {
  width: 100px;
}

.tools-unavailable {
  min-width: 10px !important;
  width: 10px !important;
}

.user-table {
  table-layout: fixed;
  width: 100%;
}

.non-scrollable-table .user-table {
  table-layout: auto;
}

.pendingReleaseTable .table.employeeTableWrapper th,
.pendingReleaseTable .table.employeeTableWrapper td {
  min-width: 80px;
}

.pendingReleaseTable .table.employeeTableWrapper > thead > tr > th:first-child,
.pendingReleaseTable .table.employeeTableWrapper > tbody > tr > td:first-child {
  min-width: 80px;
  width: 80px;
}

.pendingReleaseTable .table.progrmaTableWrapper > thead > tr > th:first-child,
.pendingReleaseTable .table.progrmaTableWrapper > tbody > tr > td:first-child {
  min-width: 80px;
  width: 80px;
}

.pendingReleaseTable .table.programsViewTable > tbody > tr > td:last-child {
  padding-right: 8px !important;
}

.linkedText {
  color: var(--text-blue-600) !important;
  cursor: pointer;
}
.linkedTextU {
  color: var(--text-blue-600) !important;
  cursor: pointer;
  text-decoration: underline;
}
.tdWidth10 {
  width: 10%;
}
.tdWidth12 {
  width: 12%;
}
.showDisclaimerText {
  margin-left: 5px;
}
.showDisclaimerText span {
  color: #3472f7;
  cursor: pointer;
}
.selectorHeader {
  max-width: 300px;
}

.filterResetBtn {
  display: flex;
  justify-content: right;
  margin-bottom: -15px;
  margin-top: 10px;
}

.textUnderLine {
  text-decoration: underline;
}

.btn-warning {
  background-color: rgb(168, 38, 50) !important;
  border-color: rgb(168, 38, 50) !important;
}

.loginWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
}
.login-left-side {
  background-color: #f7f7f8;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-right-side {
  flex: 0 0 50%;
  width: 100%;
  padding-inline: 1rem;
  max-width: 440px;
  margin-inline: auto;
}

.loginWrapper h3 {
  font-weight: 600;
}
.loginWrapper .logo-width {
  width: 500px;
  min-width: 500px;
  max-width: 300px;
}
.loginWrapper input {
  font-size: 14px;
  height: 50px;
  /*color: var(--color);*/
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding: 1rem;
  border-radius: 3px;
}
.loginWrapper input::placeholder {
  color: rgb(130, 130, 130);
}
.loginWrapper label {
  font-size: 14px;
  font-weight: 400;
}
.loginWrapper input:active,
.loginWrapper input:focus {
  border-color: var(--primary-btn-color) !important;
}
.loginWrapper p {
  color: rgb(130, 130, 130);
  font-size: 12.96px;
  font-weight: 400;
}
.loginWrapper a {
  color: var(--primary-btn-color);
  font-weight: 500;
}
.loginWrapper a.resetPassword {
  text-decoration: none;
  font-weight: 400;
}
.loginWrapper a.resetPassword:hover {
  text-decoration: underline;
  color: rgb(49, 70, 95);
}
.loginWrapper .signin-btn {
  width: 100%;
  height: 48px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  color: #ffffff;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: var(--primary-btn-color);
  border-width: 1px;
  border: 1px solid var(--primary-btn-color);
  border-radius: 0;
  opacity: 1;
}
.loginWrapper .signin-btn:disabled {
  opacity: 0.8;
  background-color: var(--primary-btn-color) !important;
  border-color: var(--primary-btn-color);
}
.uploadFileHippa {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fileNameHippa {
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #cccccc;
  padding: 0 10px;
}
.blinkText {
  position: absolute;
  top: 50px;
  right: 50px;
  color: red;
  font-weight: bold;
}
.pdfDownLink {
  text-decoration: none;
  color: #3472f7 !important;
}
.pdfDownLink:hover,
.pdfDownLink:active {
  text-decoration: none !important;
  color: #3472f7 !important;
}
.marginTop0 {
  margin-top: 0 !important;
}
.results-sent-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.registerFormWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
}
.registerWrapper {
  max-width: 500px;
  background-color: #ffffff;
  padding: 35px 30px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
}
.registerWrapper h3 {
  font-weight: 600;
}
.registerWrapper .logo-width {
  width: 200px;
}
.registerWrapper input.form-control {
  font-size: 14px;
  color: var(--color);
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding-inline: 1rem;
  height: 40px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
.registerWrapper input::placeholder {
  color: rgb(130, 130, 130);
}
.registerWrapper label {
  font-size: 14px;
  font-weight: 400;
}
.registerWrapper input:active,
.registerWrapper input:focus {
  border-color: rgb(168, 38, 50) !important;
}
.registerWrapper p {
  color: rgb(130, 130, 130);
  font-size: 12.96px;
  font-weight: 400;
}
.registerWrapper a {
  color: rgb(168, 38, 50);
  font-weight: 500;
}

.registerWrapper .signup-btn {
  width: 10 0%;
  height: 38px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  color: #ffffff;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: var(--primary-btn-color);
  border-width: 1px;
  border: 1px solid var(--primary-btn-color);
  border-radius: 0;
  opacity: 1;
}
.registerWrapper .signup-btn:disabled {
  opacity: 0.8;
  background-color: var(--primary-btn-color) !important;
  border-color: var(--primary-btn-color);
}
.codeScannerWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registered-successful {
  font-family: sans-serif;
  text-align: center;
  width: 450px;
}
.registered-successful h2 {
  font-weight: 500;
  margin: 5px;
  font-size: 16px;
}
.card-name {
  padding-bottom: 60px;
}
.registered-inner {
  padding: 30px 60px;
}
.error-message {
  text-align: center;
  color: red;
}
.form-group-wrapper .stateField {
  text-transform: uppercase;
}
.form-group-wrapper .stateField::placeholder {
  text-transform: capitalize;
}

.edit_style {
  border: 1px solid #b6b4b4;
  border-radius: 5px;
  padding: 2px 10px;
  color: gray !important;
  min-height: 40px;
}
.edit_style.selected {
  color: var(--color) !important;
}

.dobInput:read-only {
  background-color: #fff;
}
.pdfWrapper {
  height: 170vh;
  flex: 0 0 1;
  width: 100%;
}
.card .card-body {
  padding: 10px 10px;
}
.card .card-body .table {
  margin-bottom: 0;
}
.qrWrapper {
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  position: relative;
  border-radius: 10px;
  border: 1px solid lightgray;
}
.userIcon {
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.qrCode {
  margin-top: 30px;
}

.form-group-wrapper .PhoneInput input {
  height: 40px;
}
.form-group-wrapper .PhoneInputInput {
  height: 40px;
  border-radius: 3px;
  border: 1px solid rgb(196, 196, 196);
}
.form-group-wrapper .PhoneInputInput:active,
.form-group-wrapper .PhoneInputInput:focus {
  outline: none;
}
.form-group-wrapper input[type="file"] {
  height: 40px;
  padding-top: 5px;
  padding-left: 5px;
  border-radius: 3px;
}
.form-group-wrapper .vaccinationField {
  border-radius: 3px;
  position: relative;
  border: 0;
}
.form-group-wrapper .vaccinationField input[type="file"] {
  position: relative;
  opacity: 0;
  z-index: 1;
}
.form-group-wrapper .vaccinationField:after {
  content: "Upload Image";
  position: absolute;
  font-size: 14px;
  left: 5px;
  margin-top: 5px;
  background-color: var(--primary-btn-color);
  width: 100px;
  height: 30px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.profileEditImage {
  position: absolute;
  bottom: 10px;
  right: -5px;
  background-color: #f5f5f5;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
.positionRelative {
  position: relative;
}
.createShowWrapper {
  display: flex;
  justify-content: space-between;
}
.createShowInner {
  flex: 0 0 70%;
  max-width: 70%;
  width: 100%;
  padding-top: 20px;
}
.createSettingsWrapper {
  flex: 0 0 30%;
  max-width: 30%;
  width: 100%;
  padding: 20px 20px 20px 20px;
  border-left: 1px solid lightgray;
}
.fontFourteen {
  font-size: 14px;
}
/* .messages-area-show {
  padding-right: 15px;
} */
.messages-area-show .form-group-wrapper > label {
  flex: 0 0 15%;
  max-width: 15%;
}
.pr-10 {
  padding-right: 10px;
}

.filter-groups .active-group-identifier {
  height: 3px !important;
  width: 42px !important;
  background-color: var(--primary-btn-color);
}
.employee-schedule-wrapper {
  width: 400px;
}

.employee-schedule-item {
  vertical-align: middle;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}
.client-dashboard-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
}
.card-dashboard-wrapper {
  padding: 3rem 1.5rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e7eaed;
  border-radius: 0.25rem;
  flex: 0 0 calc(100% / 4 - 15px);
  max-width: calc(100% / 4 - 15px);
  column-gap: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.card-dashboard-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-wrapper {
  flex: 0 0 70%;
  max-width: 70%;
}
.card-dashboard-inner .image-wrapper {
  flex: 0 0 30%;
  max-width: 30%;
  text-align: right;
}
.content-wrapper h6 {
  margin: 0;
  padding-bottom: 1rem;
  text-transform: uppercase;
  color: #7f7d7d;
  font-size: 18px;
  font-weight: 500;
}
.content-wrapper h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}
.card-dashboard-inner .image-wrapper img {
  width: 70px;
  height: 70px;
}
.qr-code-download {
  width: 65px;
}
.qr-code-download div > i {
  font-size: 16px;
  color: green;
  display: inline-block;
  cursor: pointer;
}
.copy-link-text {
  position: absolute;
  top: 3px;
  left: 40%;
  font-size: 12px;
  color: green;
}
.codeScannerPhoneInput {
  min-height: auto;
}

/* progress bar */
.progressCounter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #08a003 !important;
}

/* Event Side Bar */
.notification-details {
  width: 20% !important;
  position: fixed !important;
  top: 10px !important;
  right: 10px !important;
  height: 150px !important;
  min-height: 150px !important;
  overflow-y: auto;
  background-color: var(--card-bg-color);
  padding-top: 50px;
}

.notification-header {
  position: absolute;
  top: 0;
  background-color: var(--amplify-primary-tint);
  color: var(--card-bg-color);
  z-index: 11000000 !important;
  width: 100% !important;
  padding: 5px;
}

.calendar-details {
  width: 100%;
  /* margin-top: 43px;   */
  display: flex;
  flex-direction: column;
  min-height: 560px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);

  border-image: initial;
}
.calendar-detail-top {
  display: flex;
  justify-content: space-between;
}

.calendar-details .details-container {
  display: grid;
  align-content: space-between;
  height: 100%;
  flex-grow: 1;
  contain: strict;
  min-height: 300px;
}

.calendar-details .details-container .notes-container .MessageInput {
  height: 140px;
  padding: 10px 16px;
  background-color: #f9f9f9;
  width: 100%;
  border: 1px solid #ddd;
}

.calendar-details .details-container .events-container {
  display: flex;
  flex-direction: column;
}

.calendar-details .details-container .events-container .event-bar {
  display: inline-flex;
}
.calendar-details .details-container .events-container .event-bar .event-bar-left {
  width: 1%;
}
.calendar-details .details-container .events-container .event-bar .event {
  color: white;
  cursor: pointer;
  padding: 6px 6px 6px 28px;
  width: 99%;
  position: relative;
}
.calendar-details .details-container .events-container .event-bar .event:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  inset: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.calendar-details .details-container .events-container .event-bar .event .eventName {
  color: #000 !important;
  position: relative;
  z-index: 2;
}
.calendar-details .details-container .events-container .event-bar .event .event-name {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selectedTextSpan {
  font-size: 14px;
}
.calendar-details .details-container .events-container {
  /* height: 512px; */
  min-height: 100px;
}

.eos-searchBar {
  width: 93% !important;
  margin-inline: auto;
  margin-top: 10px !important;
}
.height-145 {
  height: 145vh !important;
}

.eos-searchBar.showFilterActive .form-group-wrapper .globalFilterIcon {
  color: var(--amplify-primary-color);
}
.eos-searchBar .form-group-wrapper .globalFilterIcon {
  position: absolute !important;
  right: 15px !important;
  top: 12px !important;
  font-size: 22px !important;
  color: var(--bs-gray-400);
}
.globalSearchBar.eos-searchBar .form-group-wrapper .globalSearch {
  position: absolute !important;
  right: 45px !important;
  top: 12px !important;
  font-size: 22px !important;
  color: var(--bs-gray-400);
}

.events.badge-colors {
  width: 100%;
  grid-column-gap: 5px;
  column-gap: 5px;
  justify-content: center;
  flex-wrap: wrap;
}
.events.badge-colors .event-dot {
  width: 8px;
  height: 8px;
}

.custom-toolbar {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.date-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.date-container span {
  font-size: 1.7vw;
}

.eos-testing-calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-day-bg .date-container span {
  font-size: 1.3vw;
}

.height80 {
  height: 80vh !important;
}

/*custom-radio-btn-*/

.custom-radio-btn-wrapper .radio-custom {
  opacity: 0;
  position: absolute;
}

.custom-radio-btn-wrapper .radio-custom,
.radio-custom-label {
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
  width: 300px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 20px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.isPositiveResult > td {
  background-color: #fff0f0 !important;
  color: #ff2f2f !important;
}
.isStatRow > td {
  background-color: #eaf3ff !important;
}

.isCancelRow > td {
  background-color: #eda5ac46 !important;
}

.modifier {
  display: flex;
  flex-direction: row;
}

.inProgressMsg {
  border: 1px solid var(--light-gray-color);
  margin: 2px;
  background: #4c9dee;
  color: white;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  width: 250px;
  position: relative;
  padding: 4px 20px;
}
.notifyCancel {
  position: absolute;
  top: -6px;
  right: -5px;
  z-index: 11;
  color: white;
}
.notifyCancel img {
  width: 20px !important;
}
.inProgressMsg:hover {
  animation: none;
}

.inProgressMsg:active {
  color: initial !important;
  background-color: initial !important;
  border-color: initial !important;
}

.inProgressMsg {
  -webkit-animation: color-change 3s infinite;
  -moz-animation: color-change 3s infinite;
  -o-animation: color-change 3s infinite;
  -ms-animation: color-change 3s infinite;
  animation: color-change 3s infinite;
}

.upComingSchedule-item {
  vertical-align: middle;
  margin-bottom: 5px;
  cursor: pointer;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0px 20px !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.selectedScheduled {
  background-color: aliceblue !important;
}
.custom-radio-btn-wrapper .radio-custom-label {
  position: relative;
}

.custom-radio-btn-wrapper .radio-custom + .radio-custom-label:before {
  content: "";
  background: #fff;
  border: 2px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 2px;
  /*margin-right: 10px;*/
  text-align: center;
  line-height: 15px;
  position: absolute;
  right: 15px;
}

.custom-radio-btn-wrapper .radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}

.custom-radio-btn-wrapper .radio-custom:checked + .radio-custom-label:before {
  /*background: #ccc;*/
  content: "✓";
}

.flex-basis-0 {
  flex-basis: 0 !important;
}

.datefilter-label {
  padding-left: 25px;
  font-weight: bold;
}

.labDownloadTest:disabled a {
  color: grey;
}
.filterDateInput {
  flex-basis: 100% !important;
  max-width: 100% !important;
  border: 0 !important;
  background: transparent !important;
}
.personalizationCheckbox input {
  width: 20px;
  height: 20px;
}
.personalizationCheckbox label {
  font-size: 18px;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: -2px;
}

.preRegistrationOrderDetails {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  padding-inline: 1rem;
}
.statsOrderDetails {
  display: flex;
  justify-content: space-around;
}
.statsOrderDetails .preRegistrationStat {
  border-style: solid;
  flex: 1;
  padding: 10px;
  font-size: 15px;
  height: 90px;
}

.OrderDetailFont {
  font-weight: bold;
  font-size: 18px;
}
.weightBold {
  font-weight: bold;
}

.preRegistrationStat {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid;
  padding: 10px 20px;
  border-radius: 5px;
  border-style: groove;
  margin: 9px 20px 10px 0;
}
.employeeImageView > img {
  width: 418px !important;
  height: 150px !important;
}
.imageView {
  width: 120px;
  height: 120px;
}
.column-gap-15 {
  column-gap: 15px;
}
.column-gap-50 {
  column-gap: 50px;
}
.row-gap-15 {
  row-gap: 10px;
}
.scanner-wrapper {
  width: 120px;
  border: 1px solid #222;
  text-align: center;
  padding: 0px 10px 10px 10px;
}
.scanner-wrapper p {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipses;
}
.flex-wrap {
  flex-wrap: wrap;
}
.column-gap-10 {
  column-gap: 10px;
}

.custom-dropdown button {
  border: 0;
  padding: 0;
  background-color: transparent;
}
.custom-dropdown .dropdown.show .dropdown-menu {
  transform: translate3d(-95.3333px, -28.4444px, 0px);
}
.custom-dropdown button:after {
  opacity: 0;
  display: none;
}
.custom-dropdown .dropdown-item:first-child:hover {
  border-radius: 10px 10px 0 0;
}
.custom-dropdown .dropdown-item:last-child:hover {
  border-radius: 0 0 10px 10px;
}
.custom-dropdown button img {
  width: 20px;
}
.custom-dropdown button:hover,
.custom-dropdown button:active,
.custom-dropdown button:focus {
  background-color: transparent !important;
  border: 0 !important;
}
.second-column-pt {
  padding-top: 45px !important;
}

.modalHeight {
  min-height: 20vh;
}

.modal-2x1 {
  max-width: 80%;
}

.modal-3xl {
  max-width: 90%;
}
.modal-full {
  max-width: 100%;
}

.modal-2x2 {
  max-width: 90%;
}

.externalTestModal .modal-content {
  border: 1px solid black !important;
}
.testEdit-Modal-Header {
  background-color: var(--primary-btn-color) !important;
}
.edit-Button > svg {
  fill: #000000 !important;
}
/*crew-member-profile*/
.crew-member-profile .member-profile-card {
  border: 2px solid var(--card-border);
  border-radius: 0px;
}
.crew-member-profile .member-profile-card .card-header {
  background-color: var(--primary-btn-color);
  border: 2px solid transparent;
  padding: 15px;
}
.crew-member-profile .member-profile-card .card-header .card-title {
  color: var(--text-white) !important;
  font-size: 18px;
}
.crew-member-profile .member-profile-card .card-header:first-child {
  border-radius: 0;
}
.crew-member-profile .profile-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
}
.crew-member-profile .profile-image-wrapper img {
  width: 120px;
  height: 120px;
  border-radius: 0;
  object-fit: cover;
}
.crew-member-profile .profile-image-wrapper p {
  font-size: 14px;
  color: var(--light-gray-color);
}
.crew-member-profile .profile-image-wrapper small {
  color: var(--primary-btn-color);
}
.inputFileWrapper > img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
}

/* CSS for handling smaller icons and file items */
.small-file-icons {
  justify-content: flex-start;
  font-size: 10px;
  column-gap: 5px;
  flex-wrap: wrap;
  width: 85%;
}

.small-icon {
  width: 62px !important;
  height: 62px !important;
}
.remove-icon {
  position: absolute;
  right: 0;
  top: -4px;
  font-size: 20px;
}
.file-item {
  display: flex;
  align-items: center;
  margin: 5px;
}

.file-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.file-icon {
  width: 76px !important;
  height: 76px !important;
}
.file-content-wrapper {
  width: 100px;
  text-align: center;
  margin-bottom: 5px;
}

.orderTestDob .react-date-picker__wrapper .react-date-picker__inputGroup .react-date-picker__inputGroup__leadingZero {
  margin-top: 1px;
}

.personal-info-row-1 {
  width: 30%;
}

.personal-info-row-2 {
  width: 50%;
}

.crew-from-column {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.crew-form-list {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 10px;
  column-gap: 10px;
}
.flex-direction {
  flex-direction: column;
}

.form-group-wrapper .react-date-picker__wrapper {
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

.form-group-wrapper .react-date-picker__wrapper {
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 14px;
  background-color: var(--input-bg-color);
  color: var(--text-black);
  border: 1px solid var(--border-color);
  min-height: 45px;
}

.testAdmin.createClientsWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  line-height: 1;
  display: block;
}

.testAdmin.createClientsWrapper .first-last-name-wrapper {
  display: flex;
  /* align-items: center; */
  width: 100%;
  column-gap: 30px;
}
.testAdmin.createClientsWrapper .first-last-name-wrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
  display: block;
}

.testAdmin.createClientsWrapper.allowShrink .crew-profile-info {
  grid-auto-flow: column;
}

.crew-profile-complete,
.testAdmin.createClientsWrapper .crew-profile-complete {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  column-gap: 16px;
}

.testAdmin.createClientsWrapper .crew-profile-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  column-gap: 16px;
}

.testAdmin.createClientsWrapper .crew-profile-row4 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-info-cl-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  column-gap: 16px;
}
.testAdmin.createClientsWrapper .crew-profile-info-cl-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  column-gap: 16px;
}

.testAdmin.createClientsWrapper .crew-profile-row2 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1.55fr 1.55fr 1fr;
}

.testAdmin.createClientsWrapper .form-group-wrapper > label {
  color: var(--icon-gray-color);
  line-height: 1;
  padding-left: 10px;
  margin-bottom: 5px;
}
.createClientsWrapper .form-group-wrapper > label {
  color: var(--text-black);
  line-height: 1;
  padding-left: 10px;
  margin-bottom: 5px;
}
.testAdmin.createClientsWrapper .form-group-wrapper > label,
.testAdmin.createClientsWrapper .form-group-wrapper > input {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
  color: var(--text-black);
  background-color: var(--input-bg-color);
}

.testAdmin.createClientsWrapper .form-group-wrapper > input.height-32 {
  height: 32px;
}

.form-group-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 15px;
}
.form-group-wrapper > label {
  flex: 0 0 30%;
  max-width: 30%;
  width: 100%;
  margin-top: 0;
  font-size: 14px;
}

.margin-bottom-1 {
  margin-bottom: 1px !important;
}

.form-group-wrapper > input {
  flex: 0 0 calc(100% - 30% - 15px);
  max-width: calc(100% - 30% - 15px);
  width: calc(100% - 30% - 15px);
}
.form-group-wrapper.dateWrapper > input {
  flex: 0 0 calc(100% - 30% - 15px);
  max-width: calc(100% - 30% - 15px);
  width: calc(100% - 30% - 15px);
  margin-bottom: 0 !important;
}
.form-group-wrapper .textAreaWrapper {
  width: 100%;
  height: 80px;
  max-height: 80px;
  overflow-y: auto;
  resize: none;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  font-size: 15px;
  line-height: 1.2;
}
.form-group-wrapper .textAreaWrapper:hover,
.form-group-wrapper .textAreaWrapper:active,
.form-group-wrapper .textAreaWrapper:focus {
  outline: none;
}

.vaccination-rows-data {
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
}
.vc-form-group > div {
  border-right: 1px solid grey;
}
.vc-form-group:last-child > div {
  border-right: 0;
}
.vc-form-group input {
  text-align: center;
}
.vc-form-group .vc-right-image {
  border-right: 1px solid grey !important;
}
.globalSearchBar.eos-searchBar .form-group-wrapper .globalSearch {
  position: absolute !important;
  right: 14px !important;
  top: 8px !important;
  font-size: 22px !important;
  color: var(--bs-gray-400);
}
.globalSearchBar.eos-searchBar .form-group-wrapper .cancelSearch {
  position: absolute !important;
  right: 40px !important;
  top: 11px !important;
  font-size: 16px !important;
}
.createClientsWrapper .form-group-wrapper .cancelSearch {
  position: absolute !important;
  right: 20px !important;
  top: 8px !important;
  font-size: 16px !important;
}
.underlinedText {
  text-decoration: underline;
  color: var(--primary-btn-color) !important;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  color: var(--primary-btn-color);
}
.horizontalLoader {
  position: relative;
  left: 50%;
  right: 50%;
  color: var(--primary-btn-color);
}
.emp-updated-text {
  color: var(--primary-btn-color);
  font-size: 18px;
}
.safecamp-admin-wrapper > label {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
  margin-top: 0;
  font-size: 14px;
}
.safecamp-admin-wrapper > input {
  flex-basis: calc(100% - 20% - 15px);
  max-width: calc(100% - 20% - 15px);
  width: calc(100% - 20% - 15px);
}
.safecamp-admin-wrapper .PhoneInput {
  width: calc(100% - 75px);
}
.hs-dashboard-wrapper .bg-start-date {
  background-color: rgba(249, 177, 31, 0.1);
  display: flex;
  padding: 15px 10px !important;
  border: 0;
}
.hs-dashboard-wrapper .bg-start-date span {
  font-size: 14px;
  color: #464646;
  letter-spacing: 1px;
}
.hs-dashboard-wrapper .bg-start-date p {
  color: #f9b11f;
  font-size: 20px;
  letter-spacing: 1px;
}
.hs-dashboard-wrapper .bg-running-days {
  background-color: rgba(240, 90, 39, 0.2);
}
.hs-dashboard-wrapper .bg-running-days p {
  color: #f05a27;
}
.hs-dashboard-wrapper .last-p-test {
  background-color: rgba(59, 134, 255, 0.2);
}
.hs-dashboard-wrapper .last-p-test p {
  color: #3b86ff;
}

.box-height {
  height: 340px;
  padding: 1rem;
}
.box-inner-height {
  height: 270px;
}

/*.dashboard-wrapper.client-dashboard-inner .table {*/
/*  border: 1px solid var(--border-color);*/
/*}*/
.dashboard-wrapper.client-dashboard-inner .table tr {
  display: revert;
  border-bottom: 1px solid var(--border-color);
}
.dashboard-wrapper.client-dashboard-inner .table th,
.dashboard-wrapper.client-dashboard-inner .table td {
  width: calc(100% / 6);
  padding: 12px;
}
/**/

/*filters styling*/
.header-btns {
  padding: 10px 0;
  /* align-content: stretch; */
  cursor: pointer;
  width: 100px;
  height: 85px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.header-btns .header-btn-img {
  padding-block: 6px;
  border-right: 1px solid #d7d4d4;
  width: 100px;
  text-align: center;
}
.header-btns:last-child .header-btn-img {
  border-right: 0;
}
.header-btns .header-btn-labels {
  font-size: 12px;
  min-width: 95px;
  text-align: center;
  color: var(--light-gray-color);
  /* height: 10%; */
}
.header-btns.disabled {
  cursor: default;
  pointer-events: none;
}
.filter-nav-wrapper {
  border-bottom: 1px solid #f5f5f5;
}
/*end filters styling*/

.multi-image-area {
  min-height: 60px;
}
.preRegisterationBullet {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 5px;
}
.preRegisterationBullet.red {
  background-color: red;
}
.preRegisterationBullet.green {
  background-color: green;
}
.trDataWrapper {
  background-color: #ffffff;
  border-bottom: 1px solid transparent;
}
.trDataWrapper td {
  padding-block: 8px;
}

.trDataWrapperGray {
  background-color: #f2eeee;
}
.trDataWrapperHeader {
  background-color: white;
  width: 100%;
}

.shows-screen-wrapper {
  position: relative;
}

.testTabelDeleteIcon {
  width: 15px;
}

.testPrintIcon {
  width: 25px;
}

.shows-screen-wrapper .card-title > div {
  font-size: 18px;
  color: var(--text-black-color);
}
.shows-screen-wrapper .card-title > div span {
  color: #08a003;
  font-size: 18px;
  display: inline-block;
}
.paginationText-alignment {
  position: absolute;
  right: 0;
  top: 22px;
}
.selectedTestsText {
  position: absolute;
  left: 3px;
  bottom: 2px;
}
.selectedTestsText span {
  font-size: 14px !important;
}
.shows-filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 10px;
}
.shows-filter-inner {
  display: flex;
  flex-wrap: wrap;
}

/* filters styling */
.filterResetBtn {
  display: flex;
  justify-content: right;
  position: relative;
  top: 15px;
  right: 15px;
}
.custom-filters-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #f2f2f2 !important;
  padding-block: 30px;
}
.custom-filters-wrapper .input-group {
  display: block;
  flex: 0 0 calc(25% - 16px);
  width: calc(25% - 16px);
  margin-inline: 8px;
}
.custom-filters-wrapper .input-group > span,
.custom-filters-wrapper .input-group > input {
  width: 100% !important;
  max-width: 100% !important;
}
.custom-filters-wrapper .input-group > span.input-group-text {
  background-color: transparent;
  border: 0;
}
.custom-filters-wrapper .input-group > input {
  border: 1px solid #ddd !important;
  height: 45px;
  border-radius: 5px;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.custom-filters-wrapper .filterSelector {
  width: 100%;
  max-width: 100% !important;
}
/* filter styling end */

/* status styling */
.star-icon-wrapper .gray {
  color: #d7d4d4;
}
.star-icon-wrapper .green {
  color: green;
}
.star-icon-wrapper .blue {
  color: blue;
}
.star-icon-wrapper .yellow {
  color: #ffd300;
}
.star-icon-wrapper .orange {
  color: rgb(255, 102, 0);
}
.star-icon-wrapper .maroon {
  color: rgb(168, 38, 50);
}
.maroon {
  color: rgb(168, 38, 50);
}
.green {
  color: green;
}
.red {
  color: rgba(228, 3, 3, 0.845);
}
.star-icon-wrapper .sm {
  font-size: 12px;
}
.star-icon-wrapper .md {
  font-size: 16px;
}
.star-icon-wrapper .lg {
  font-size: 20px;
}
/* end status styling */

/* Munazzam */
.settingPopupWrapper {
  border-bottom: none;
  padding: 16px !important;
  background-color: transparent !important;
  margin-bottom: 0 !important;
}
.settingPopup .settingPopupWrapper .btn-close {
  background-color: #aeaeae !important;
  border-radius: 50%;
  opacity: 1 !important;
  background-image: none !important;
}
.settingPopupMain {
  transform: translateY(110px);
}
.settingRightCol {
  background-color: var(--main-body-bg);
  border-radius: 0 0.5rem 0.5rem 0;
}
.settingPopup .settingText {
  color: var(--text-black-color);
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 17px;
}
.settingPopupMain span {
  padding-bottom: 150px;
}
.settingPopupMain h4 {
  font-size: 20px;
  color: var(--text-black-color);
}
.settingPopupMain p {
  font-size: 16px;
  color: var(--text-black);
}
.settingPopupMain .updateBtn {
  background: var(--btn-danger);
  border: none;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 100px;
}
/* default modal design */
.DefaultModalHeader {
  background: transparent !important;
}
.updateRightSide .customRow {
  column-gap: 15px;
}
.updateRightSide .input {
  border-radius: 0px;
  padding: 5px 10px;
  border: 1px solid var(--border-color);
  background-color: transparent;
  font-size: 14px;
}
.updateRightSide .input::placeholder {
  color: var(--text-black-color);
}
.updateRightSide .customRow .label {
  font-size: 14px;
  margin-bottom: 0 !important;
  color: var(--light-gray-color);
}
.customRow .customTextArea {
  resize: none;
  height: 80px;
}
.customRow .customTextArea,
.customRow .customSelect {
  border: 1px solid var(--border-color);
  background-color: transparent;
}
.customRow .reTestVerbiage {
  flex: 0 0 66%;
}
.customRow .insertField {
  flex: 0 0 32%;
}
.customRow .customSelect {
  border-radius: 0 !important;
}
.DefaultModalHeader .modal-body {
  padding-bottom: 110px;
}
/* .customUpdateRow {
  padding: 24px;
} */
.updateLeftSide {
  padding: 49px 16px 16px 24px;
}
.settingLeftSide {
  padding: 49px 40px 16px 40px;
}
.updateRightSide,
.settingRightSide {
  padding: 49px 24px 16px 16px;
}
.updateRightSide .updateBtn {
  padding: 40px 0;
}
.updateRightSide .updateButton {
  background: var(--btn-danger);
  border: none;
  float: right;
  width: 300px;
  padding-block: 5px;
}
.updateText {
  color: var(--text-black-color) !important;
}
.form-select:focus {
  box-shadow: none;
}
.customInput {
  height: 38px;
  border-radius: 3px !important;
}
.dateRangePickerWrapper .header-btns:last-child .header-btn-img {
  border-right: 1px solid #d7d4d4;
}
.daterangepicker:before {
  display: none !important;
}
.secondary-insurance-change .secondary-edit-input {
  background-color: transparent !important;
}

.hidebordar {
  border: none;
  max-width: 70%;
}
.text-right {
  text-align: right;
}

.userPermission {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr;
  width: 100%;
  column-gap: 16px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.userPermissionChecks {
  /* display: flex;
  width: 100%;
  margin-left: 10px;
  margin-bottom: 5px;
  flex-wrap: wrap; */
}

.list-item-ins {
  font-size: 14px;
  align-items: center;
  margin: 0;
  justify-content: space-between;
  padding-left: 10px;
  color: var(--text-black);
}
.secondary-insurance-change {
  border: 0;
  border-bottom: 1px solid #ddd;
}
.printoptionsModalWidth .modal-content {
  max-width: 384px;
}
.permissionBox {
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
}
.permissionBoxRoles {
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}
.manage-permissions-heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.modal-body-bg-gray {
  background-color: #f2f2f2;
}
.proxy-box-shadow {
  box-shadow: inset 0 0 2px rgba(20, 19, 19, 0.5);
  padding: 1rem;
  border-radius: 5px;
}
.filterSwitchButton {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-black);
  background-color: #fff;
  grid-column-gap: 15px;
  column-gap: 15px;
  transition: 0.5s ease;
  font-size: 14px;
  border-radius: 5px;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid var(--border-color);
}

.reason-textarea {
  display: block;
  overflow: hidden;
  resize: both;
  min-height: 100px !important;
  line-height: 30px;
  width: 400px !important;
}

.tcMatrix-custom-notification-wrapper > div > div {
  inset: initial !important;
  bottom: 10px !important;
  right: 15px !important;
}

.infoToast {
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center;
  border: 1px solid var(--border-color);
  min-width: 200px;
}

.toast {
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center;
  border: 1px solid var(--border-color);
  min-width: 200px;
  /* Adjust the minimum width as needed */
}

.infoToast > div:nth-child(1) {
  margin: 0 !important;
  margin-right: 10px !important;
  min-width: 80px;
}

.infoToast > div:nth-child(1) > div:nth-child(1) {
  height: 29px !important;
}

.toast > div:nth-child(1) {
  margin: 0 !important;
  margin-right: 10px !important;
  min-width: 80px;
}

.toast > div:nth-child(1) > div:nth-child(1) {
  height: 20px !important;
  /* Adjust the height as needed */
  bottom: 8px !important;
}

.toast > div:nth-child(1) > div:nth-child(2) {
  top: 14px !important;
}
.payerInformation-effective .col {
  flex: 0 0 19%;
}
.payerInformation-effective .effective-from-width {
  flex: 0 0 22%;
}
.documents-update-patient-table .trDataWrapper .form-check-input[type="checkbox"] {
  margin-top: 6px;
}
/* .documents-update-patient-table .table>thead>tr>th,
.documents-update-patient-table .table>tbody>tr>th,
.documents-update-patient-table .table>tfoot>tr>th,
.documents-update-patient-table .table>thead>tr>td,
.documents-update-patient-table .table>tbody>tr>td {
  padding: 4px 8px;
} */
.profile-detail-payer-info .label-text {
  width: 30%;
}
.row-gap-10 {
  row-gap: 10px;
}

.testAndAssayManagementModal .css-1okebmr-indicatorSeparator {
  display: none;
}
.testAndAssayManagementModal .test-selector-edit-icon {
  width: 40px;
}
.manageText {
  position: absolute;
  right: 15px;
  font-size: 13px;
  color: #08a003;
  cursor: pointer;
}
.test-component-element .react-datepicker__close-icon::after {
  background-color: transparent;
  font-size: 1rem;
  color: red;
}
.notInlineLabel {
  position: absolute;
  top: 50px;
  right: 10px;
}
.leading-0 {
  line-height: 0;
}
.border-b-1 {
  border-bottom: 1px solid #d1d1d1;
}

.test-component-element.switch-wrapper {
  padding: 20px 10px 10px;
}
.test-component-element .testInlineLabel {
  font-size: 18px;
  font-weight: 500;
  color: #353e59;
}
.test-component-container.column-full-height {
  height: calc(100% - 32px);
}
.test-component-element .react-datepicker-wrapper {
  width: 100%;
}
.test-type-container .accordion-button {
  font-size: 18px;
  font-weight: bold;
  color: #23201f;
  letter-spacing: 1px;
  background-color: transparent;
}
.test-type-container .accordion-button:focus {
  outline: none;
  border: initial;
  box-shadow: none;
}
.test-type-container .accordion-button::after {
  filter: grayscale(100%);
}
.block-section {
  background-color: #f2f2f2;
  padding-block: 10px;
  padding-inline: 10px;
  margin-bottom: 5px;
  border-radius: 10px;
}
.range-view-container {
  margin-top: 0;
  background-color: #f2f2f2;
}
.bothScroll {
  max-height: 370px;
  overflow-y: auto;
}

/* breakpoints */
@media only screen and (max-width: 576px) {
  .updateRightSide .customRow {
    flex-direction: column;
  }
  .updateRightSide .updateButton {
    width: 242px;
  }
}
@media only screen and (max-width: 768px) {
  .dictionary_search {
    width: 100%;
  }
  .settingPopupMain {
    transform: translateY(0px);
  }
  .settingPopupMain span {
    padding-bottom: 65px;
  }
  .settingRightSide {
    padding: 49px 24px 30px 16px;
  }
}
/* end Munazzam */

/*breakPoints*/
@media only screen and (min-width: 1366px) {
  .desktop-noScroll .table-responsive {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media only screen and (min-width: 1600px) {
  .filterDrop {
    width: 150px;
  }
  .dashboard-wrapper .dashboard-tables-data tbody {
    max-height: 32.5vw;
  }
}
@media only screen and (min-width: 1750px) {
  .dashboard-wrapper .dashboard-tables-data tbody {
    max-height: 33.5vw;
  }
}
@media only screen and (min-width: 1920px) {
  .dashboard-wrapper .dashboard-tables-data tbody {
    max-height: 25vw;
  }
}
@media only screen and (min-width: 2100px) {
  .dashboard-wrapper .dashboard-tables-data tbody {
    max-height: 26vw;
  }
}

@media only screen and (min-width: 2260px) {
  .dashboard-wrapper .dashboard-tables-data tbody {
    max-height: 28vw;
  }
}

@media (max-width: 991px) {
  .crew-from-column {
    flex-direction: column;
  }
  .changeRoleSelector {
    width: 100%;
  }
  .system-settings-wrapper .headerButton.btn-secondary {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 992px) {
  .createClientsWrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .showsModal div .checkBoxWrapper span {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .labsModalWrapper {
    flex-direction: column;
  }
  .labsModalWrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .uploadFilesArea {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .dashboard-wrapper .dashboard-tables-data tbody {
    max-height: 43vw;
  }
}
@media only screen and (max-width: 1199px) {
  .createShowWrapper {
    display: block;
  }
  .createShowInner,
  .createSettingsWrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .messages-area-show {
    padding-right: 0;
  }
  .messages-area-show .form-group-wrapper > label {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .createSettingsWrapper {
    border-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .dashboard-wrapper .dashboard-tables-data tbody {
    max-height: 36vw;
  }
}
@media only screen and (max-width: 1366px) {
  .card-dashboard-wrapper {
    flex: 0 0 calc(100% / 3 - 15px);
    max-width: calc(100% / 3 - 15px);
  }
}
@media only screen and (max-width: 1199px) {
  .preRegistrationOrderDetails {
    justify-content: space-between;
  }
  .preRegistrationStat:nth-child(2n) {
    margin-right: 0;
  }
  .preRegistrationStat {
    flex: 0 0 calc(100% / 2 - 40px);
  }
}
@media only screen and (max-width: 1024px) {
  .card-dashboard-wrapper {
    flex: 0 0 calc(100% / 2 - 15px);
    max-width: calc(100% / 2 - 15px);
  }
}
@media only screen and (max-width: 992px) {
  .vc-form-group > div {
    border-right: 0;
    border-bottom: 1px solid grey;
  }
  .vc-form-group:last-child > div {
    border-right: 0;
    border-bottom: 0;
  }
  .vc-form-group input {
    text-align: center;
  }
  .vc-form-group .vc-right-image {
    border-right: 0 !important;
    border-bottom: 1px solid grey !important;
  }
  .preRegistrationStat {
    flex: 0 0 calc(100% / 2 - 40px);
  }

  .testAdmin.createClientsWrapper .crew-profile-info,
  .testAdmin.createClientsWrapper .crew-profile-row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .shows-filter-wrapper,
  .shows-filter-inner {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 576px) {
  .card-dashboard-wrapper {
    flex: 0 0 calc(100% / 1 - 0px);
    max-width: calc(100% / 1 - 0px);
  }
  .preRegisterationViewWrapper {
    margin-left: 0;
    width: calc(100% - 0px) !important;
  }
  .preRegistrationStat {
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 100%;
  }
  .testAdmin.createClientsWrapper .crew-profile-info,
  .testAdmin.createClientsWrapper .crew-profile-row2 {
    display: block;
  }

  .loginWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f7f7f8;
    padding-block: 30px;
  }

  .login-left-side {
    flex: 0 0 100%;
    max-width: 100%;
    background-color: transparent;
  }

  .login-left-side {
    min-height: auto;
  }

  .login-right-side {
    padding-top: 30px;
    min-height: auto;
    /* flex: 0 0 80%; */
    /* max-width: 80%; */
  }
}

.qrCodeWrapper {
  border-radius: 10px;
  background-color: #fff;
  color: #000000;
  border: 1px solid lightgray;
  padding-top: 20px;
  padding-bottom: 20px;
}
.qrSubdiv {
  border: none;
}
.printQrCode {
  display: none;
}
@media print {
  @page {
    size: 4in 6in;
  }
  .registered-successful h3,
  .registered-successful p,
  .qrCodeWrapper,
  .qrCodeWrapper .qrSubdiv,
  .downloadQrBtn {
    display: none;
  }
  .qrCodeWrapper.printQrCode {
    max-width: 100px;
    margin-inline: auto;
    padding-block: 5px;
    display: block;
  }
  .printQrCode #react-qrcode-logo {
    width: 75px !important;
    height: 75px !important;
  }
  .printQrCode .qrSubdiv {
    font-size: 10px !important;
    display: block;
  }
}

.md-customer-reader > div > div {
  width: 100% !important;
  height: 70px;
}

.text-lightgrey {
  color: #767171;
}

.text-alignjustify {
  text-align: justify;
}

.video-iframe {
  width: 100%;
  height: 300px;
}

.hipaa-answer-wrong {
  background-color: #ffafaf;
  padding: 5px;
  width: 95%;
  margin: auto auto 5px;
}

.borderless-checkbox label {
  border: 0 !important;
}

.checkbox-min-width {
  min-width: 50px;
}

.cptCodeTag {
  padding: 8px;
  background-color: #d3d3d3;
  border-radius: 5px;
  display: flex;
  width: 100px;
  margin-right: 10px;
  margin-top: 10px;
}

.ictCodeTag {
  padding: 8px;
  background-color: #d3d3d3;
  border-radius: 5px;
  display: flex;
  width: 62px;
  margin-right: 10px;
  margin-top: 10px;
}

.cptCodeTag img {
  width: 22px;
  height: 21px;
  margin-left: 7px;
}

.templateStyle {
  background: #f2f2f2;
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.templateStyleHeader {
  background: #f2f2f2;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 12px 8px 14px 8px;
  border: 1px solid #8a948a;
}
.templateStyleHeader div {
  font-weight: 600;
  color: var(--btn-bg-black);
}

.checkbox-button-wrapper input[type="checkbox"] {
  display: none;
}

.checkbox-button-wrapper label {
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  padding-left: 25px;
}

.isPositiveResult > td {
  background-color: #fff0f0 !important;
  color: #ff2f2f !important;
}

.checkbox-button-wrapper label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: var(--text-white);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all 500ms ease;
}
.checkbox-button-wrapper input[type="checkbox"]:checked + label::before {
  border-color: var(--btn-danger);
  background-color: var(--btn-danger);
  transition: all 0.5s ease-in;
}
.checkbox-button-wrapper.colorBlack input[type="checkbox"]:checked + label::before {
  border-color: #000000;
  background-color: #000000;
}
.table-noresponsive {
  overflow: unset;
}

.simple-form-group-wrapper {
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  /* margin-bottom: 15px; */
}
.simple-form-group-wrapper > label {
  flex: 0 0 30%;
  max-width: 30%;
  width: 100%;
  margin-top: 0;
  font-size: 14px;
}

.simple-form-group-wrapper .react-date-picker__wrapper {
  border-radius: 5px;
  padding: 2px 10px;
  background-color: var(--input-bg-color);
  border: 1px solid var(--border-color);
  color: var(--text-black);
  min-height: 45px;
}

.test-selector-add-icon img {
  width: 25px;
  height: 25px;
}

.label-60-per {
  flex: 0 0 60% !important;
  max-width: 60% !important;
}

.mr-50-per {
  margin-right: 50% !important;
}
.mr-10-per {
  margin-right: 10% !important;
}
.navFooter {
  bottom: 0;
  position: fixed;
  text-align: center;
  font-size: 14px !important;
  font-weight: 350;
  color: var(--dark-gray-text);
  padding: 20px;
  width: 260px;
  /* Adjust the width to fit your content */
}

.cool-scrollbar {
  height: 60vh;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(43, 120, 208, 0.2) transparent;
}

.cool-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.cool-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

.cool-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Ranges Section */

.range-special-bg {
  background: #fdf8f8;
}

.test-donw-select {
  width: 200px !important;
}

.preview-modal {
  z-index: 10000;
}

.user-table-height {
  height: 60px;
}

/* Test Type Modal New Css  */

.test-type-container {
  display: flex;
}

.test-type-column {
  box-sizing: border-box;
  margin: 5px;
}

.test-type-column-70 {
  width: 70%;
  /* background-color: #f0f0f0;  */
}

.test-type-column-30 {
  width: 30%;
  /* background-color: #d0d0d0;  */
}

.test-component-container {
  padding: 10px;
  border: 1px solid var(--gray-secondary-color);
  border-radius: 10px;
}

.test-component-title {
  margin-left: 10px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #23201f;
  letter-spacing: 1px;
}

.test-type-input-label {
  padding-bottom: 0;
  padding-top: 5px;
  margin-bottom: 5px;
  margin-inline: 10px;
  font-weight: bold;
  color: #7d859c;
  height: 30px;
  letter-spacing: 1px;
}

.test-component-elements {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.test-component-element {
  flex: 0 0 calc(33.33% - 16px);
  box-sizing: border-box;
}
.test-component-element-66 {
  flex: 0 0 calc(66.66% - 16px);
  box-sizing: border-box;
}

.selector-label-container {
  display: flex;
  justify-content: space-between;
}

.test-element-option-container {
  border: 1px solid lightgray;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin-right: 10px;
}

.test-element-option-container-selected {
  background: #5890ff;
  color: white;
}

.test-element-option-container:hover {
  cursor: pointer;
}

.test-element-option-container p {
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.test-element-container {
  display: flex;
  align-items: center;
}
.test-element-view-label {
  margin: 0;
  padding: 0;
  font-weight: 500;
  cursor: pointer;
  color: #5890ff;
  font-size: 20px;
}

.paddingLeft-0 {
  padding-left: 0px !important;
}

/* Dragable component styling */

.draggable-item {
  padding: 16px;
  margin-bottom: 8px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  cursor: move;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid gray;
  transition: background 0.3s ease, opacity 0.3s ease;
}
.draggable-item.draggable-item-gray{
  background-color: #6c6d80;
  font-weight: bold;
  cursor: default;
  color:white


}
.draggable-item.dragging {
  opacity: 0.5;
}

.draggable-item.drag-over {
  background-color: #f0f0f0;
}



/* tabs style override */

.react-tabs__tab--selected{
  font-weight: bold;
  color: #5890ff !important;
  /* border-bottom: 1px solid #aaa !important; */
}
.custom-filter-tabs .react-tabs__tab:focus:after {
  background-color: transparent;
}
.custom-filter-tabs .react-tabs__tab {
  border: 2px solid transparent;
}
.custom-filter-tabs .react-tabs__tab-list{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-bottom: 0;
}
.custom-filter-tabs .react-tabs__tab--selected {
  background: transparent;
  border: 2px solid transparent;
  border-bottom: 2px solid #5890ff;
  border-radius: 0;
}
.selected-values-update-user  {
  padding-block: 10px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    border-bottom: 1px solid #a19fa0;

}
.selected-values-update-user .selected-value-badge {
    background-color: hsl(0, 0%, 90%);
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}
.selected-values-update-user .badge-remove {
  cursor: pointer;
  font-weight: 700;
  padding-left: 5px;

}

.flex-1{
  flex:1
}


