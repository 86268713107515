.range-view-container {
  /* margin-top: 10px; */
  /* border: 1px solid #dddddd; */
  padding: 10px;
}

.range-view-table {
  width: 100%;
  border-collapse: collapse;
}

.range-view-th,
.range-view-td {
  text-align: left;
  padding: 8px;
  border: 1px solid #dddddd;
}
.range-view-td {
  background-color: #fff;
}
.range-view-th {
  background-color: #f2f2f2;
  text-align: center;
}

.range-view-input {
  width: calc(100% - 10px);

  padding: 5px;
  box-sizing: border-box;
  margin: 5px 0;
}
.range-view-input-100 {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  margin: 5px 0;
}

.age-range {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 5px;
}

.delete-icon {
  width: 20px;
  height: 20px;
}
.delete-icon-small {
  width: 15px;
  height: 15px;
}


.roles-icon {
  width: 35px;
  height: 35px;
}