.download-icon {
  font-size: 16px;
  color: green;
}
.test-details-wrapper .img-direction {
  flex-direction: column;
  justify-content: center;
}
.dateWrapperAgain {
  flex-direction: column;
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
.clientWrapperUniq {
  flex-direction: column;
  align-items: start;
  flex: 0 0 25% !important;
  max-width: 25% !important;
  padding-left: 10px;
  padding-right: 10px;
}
.clientWrapperUniq > input,
.dateWrapperAgain > input {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
  flex-basis: 100% !important;
  max-width: 100% !important;
}
.checkBoxChange {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  color: 636363;
  /* margin-left: 10px; */
  padding: 9px 0;
  font-size: 14px;
}
.clientWrapperAgain {
  /* flex-wrap: nowrap !important; */
  column-gap: 0px;
}
.test-details-wrapper .img-direction img {
  width: auto !important;
}
.model-runner {
  padding: 0 !important;
}
.form-wrapper-ed {
  flex-direction: column;
  align-items: start;
  flex: 0 0 33.33% !important;
  max-width: 33.33% !important;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}
.messages-area-show .re-test {
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
.messages-area-show .re-test .selectField {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding-left: 10px;
}
.updateButtonRetest .modalButtons {
  background: var(--btn-danger);
  border: none;
  width: 300px;
  padding-block: 10px;
  border-radius: 1px;
  color: #fff;
}
.showRunnerArea .headingRunner {
  flex: 0 0 100%;
  max-width: 100%;
}
.orderCutdate {
  padding-left: 10px;
  padding-right: 10px;
}
.TextareaWrap .textAreaCustom {
  height: 94px;
  max-height: 94px;
}
.messages-area-show .re-test .selectTextarea {
  flex: 0 0 66.67%;
  max-width: 69.67%;
  padding-right: 5px;
}
.form-wrapper-ed > input {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
  flex-basis: 100% !important;
  max-width: 100% !important;
  width: 100%;
}

.form-group-wrapper.re-test .modalLineHeaders {
  max-width: 100%;
  flex: 0 0 100%;
}

.updateRightSid .form-group-wrapper > label {
  flex: 0 0 100%;
  max-width: 100%;
}

/* adeel media */
.createSettingsWrapper {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.safecamp-admin-wrapper .PhoneInput {
  /* width: calc(100% - 75px); */
  width: 100%;
}
.safecamp-admin-wrapper > input {
  flex-basis: calc(100% - 20% - 15px);
  max-width: calc(100% - 20% - 15px);
  width: calc(100% - 20% - 15px);
}
.safecamp-admin-wrapper > label {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
  margin-top: 0;
  font-size: 14px;
}
.form-wrapper-ed.safecamp-admin-wrapper .PhoneInput input {
  height: 36px;
}
@media only screen and (max-width: 992px) {
  .form-wrapper-ed,
  .clientWrapperUniq {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .messages-area-show .form-group-wrapper > label {
    flex: 0 0;
  }

  .dateWrapperAgain {
    flex-direction: column;
    align-items: start;
    flex: 0 0 50% !important;
    max-width: 50% !important;
    padding-left: 10px;
    padding-right: 10px;
  }

  .showRunnerArea {
    flex-wrap: wrap;
  }
}
.custum-file-upload {
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 2px dashed #cacaca;
  background-color: rgba(255, 255, 255, 1);
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px rgba(0,0,0,0.1);
}

.custum-file-upload .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .icon svg {
  height: 80px;
  fill: rgba(75, 85, 99, 1);
}

.custum-file-upload .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .text span {
  font-weight: 400;
  color: rgba(75, 85, 99, 1);
}

.custum-file-upload input {
  display: none;
}

.cancel-btn {
  position: absolute;
  right: 10px;
  bottom: 330px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ff4444;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 1px 2px solid #424242;
  justify-content: center;
  padding: 0;
}

.cancel-btn:hover {
  background-color: #ff0000;
}
